/**
 * GFSelect == Game Factory Select not to reuse the Select name
 */
import React, { useRef, useLayoutEffect } from "react";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';

import useStyles from './styles';


function GFTypeSelect(props) {

	const classes = useStyles()

	const inputContainerRef = useRef();
	useLayoutEffect(() => {
		const input = inputContainerRef.current.querySelector("input");
		input.setAttribute("type", "select");
		input.setAttribute("autocomplete", "new-password")
		if (props.required) {
			input.setAttribute("required", "");
		}

	}, [props.required]);

	return (
		<FormControl required className={classes.formLine} ref={inputContainerRef}>
	        <InputLabel
	            className={classes.inputLabel}
	            classes={{asterisk: classes.inputLabelAsterisk}}
                shrink
	            >{props.labelText}</InputLabel>
            <Autocomplete
                classes={{inputRoot: classes.autocompleteInputRoot}}
                name={props.name}
                options={props.options}
                forcePopupIcon={true}
                onChange={props.onChange}
                value={props.value}
                required={props.required}
                renderInput={(params) => {
                  return (
                    <TextField {...params} required={props.value && props.value.length === 0} variant="filled" InputLabelProps={{shrink:true}}/>
                  );
                }}
            />
			<FormHelperText>{props.hintText}</FormHelperText>
	    </FormControl>
	);
}

GFTypeSelect.propTypes = {
  name: PropTypes.string,
  labelText: PropTypes.string,
  hintText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool
};

export default GFTypeSelect;
