const skillTree = require("./commonSettings/skillTree");
const countries = require("./commonSettings/countries");
const employmentOptions = require("./commonSettings/employmentOptions");

const config =  {
  "skillTree": skillTree,
  "countries": countries,
  "employmentOptions": employmentOptions,
  formRecruitFieldMapping: {
    "firstName": "First Name",
    "lastName": "Last Name",
    "phone": "Phone",
    "emailAddress": "Email",
    "jobTitle": "Job Title",
    "experienceYears": "Experience in Years",
		"gamesIndustryYears" : "Years of experience in Games industry",
    "seniorityLevel": "What is your seniority level?",
    "seniority_old": "Seniority Level",
    "category": "CATEROGRY",
    "specialities": "What are your key specialities?",
    "specialities_old": "SPECIALITY",
    "skills": "SKILLS",
    "workExperience": "Work Experience",
    "salaryExpectations": "Expected Salary",
    "linkedInLink": "LinkedIn profile",
    "gitHubLink": "GitHub, portfolio",
    "location": "Location",
    "employmentOptions": "Type of Employment",
    "workPreferences": "Work Preferences",
    "additionalInfo": "Additional Info",
    "country" : "Country",
    "city" : "City",
    "readyToRelocate": "Relocation",
    "agreeToJoinBoard": "Interested to join Talent Board",
    "dateAppliedyyyymmdd": "Date Applied",
    "source": "Source",
    "artAndAnimationStyles": "Art Styles",
		"titlesAndThingsToBeProudOf": "Titles and personal achievements",
    "gameTitles": "Published Game Titles or Apps",
    "motivation": "What motivates you?",
    "expectations": "What are your expectations of the Team / Company?",
    "dreamJob": "What is your dream job?",
    "tasks": "What are your key tasks and responsibilities?",
    "achievements": "Achievements and things you are proud of",
		"gameGenres": "Genres",
		"gameEngines": "Engines",
		"gamePlatforms": "Platforms",
		"associatedTags": "Associated Tags",
		"addCandidate": "Allow publish profile on Talent Board"
    // "termsAcceptance": ""
  },
  tagToAssociateWithSubmissions: "",
  cvFileFetchTimeout: 5000, // up to 5 sec for fetching the cv file from network
  maxCVFileSizeMB: 5 // in MB, has to be integer
};

// export default config;
module.exports = config;
